* {
  box-sizing: border-box;
  margin:0;
  padding:0;

}


body {
    font-family: 'Work Sans', sans-serif, Times;
    font-size: 1.1em;
    line-height: 1.5;
    font-weight: 200;
    --purple: #4C2157;
    --bg-blue: #d7ddf1;
    --bg-rose: #f8e5f1;
    --bright-lila: #cdb5d8;
    --lila: #a185bd;
    --white: #fefefe;
    --black: #000000;
    margin:0;
    background-color:rgba(251, 243, 255, 0.6) ;
}

body:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url('./pictures/background_50.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 0% 20%;
    background-size: 100%;
    background-repeat: no-repeat, repeat;

}

/*
.background {
    background-image: url('background_50.png');
    background-repeat: no-repeat, repeat;
    background-position: 0% 20%;
    background-size: 100%;
    background-attachment: fixed;
}
*/


a{
    font-size: 1.2em;
    font-weight: 200;
    text-transform: lowercase;
    color: var(--black);
    cursor: pointer;



}


a:link{
    text-decoration: none;
    cursor: pointer;

}


a:visited{
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
}

a:hover{
  color: var(--lila);
  cursor: pointer;
}


a:focus{
  color: var(--lila);
  cursor: pointer;

}







h1{
    font-size: 2.1em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 12px 0 24px 0;
    font-weight: 200;

}

h2 {
    font-size: 1.4em;
    line-height: 1.2em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 24px 0 12px 0;

}

p{
  margin: 12px 0 12px 0;
}

.lead{
  font-size: 1.2em;
}



.hide-on-large{
  display: none;
}



/***********************************************Header****************************************************/

header {
    margin-top: 24px;
    width:100%;
}

.logo {
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.nav-ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: None;
    margin-top: 50px;
}
.nav-li{
    padding-right: 4rem;
}

/***********************************************Main****************************************************/


.rectangle {
    border: solid rgba(255, 255, 255, 1)  16px;
    margin: 0 15% 0 15%;
    padding: 24px 48px 24px 48px;
}

main {
    margin-top: 48px;
}

.button{
  border-color: rgb(0,0,0,0.5);
  border-style: solid;
  border-width: 1px;
  padding: 12px 24px 12px 24px;
  font-size: 1em;
  text-transform: lowercase;
  cursor: pointer;
}

.button:hover{
  border-color: rgb(255,255,255);
  background-color: var(--lila);
  color: var(--white);
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

/***********************************************Home****************************************************/

.home{
  text-align: center;
}

.teaser-container{
  display: flex;
  flex-direction: row;
  padding: 48px 0 24px 0;
}

.teaser{
  display: block;
  width: 33%;
  margin: 24px 24px 0 24px;
}

/*.teaser img:hover{
  transform: scale(1.1);
  transition: transform .2s;
}*/

.teaser img{
  max-width: 70%;

  /*box-shadow: 5px 5px 5px 5px rgba(35, 15, 54, 0.1);*/
}

/*.diamond {
  margin: 48px 0 96px 0;
	width: 160px;
	height: 160px;
	transform: rotate(45deg);
	outline: 5px solid white;
	overflow: hidden;
  display: block;

  margin-left: auto;
  margin-right: auto;

}

.diamond img {
	max-height: 160%;
	transform: rotate(-45deg);
	margin: -10% -80%;
  position: absolute;
  top: 10px;
  right: 20px;
}
*/
.teaser-text{
  margin: 24px 24px 48px 24px;
}

/***********************************************About****************************************************/

.img-about{
  float: left;
  max-width: 32%;
  margin: 0 24px 24px 0;
}

.flex-about{
  display: flex;
  width:100%;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
}





/***********************************************Angebot****************************************************/
.img-angebot{
  max-width: 50%;
  float: left;
  margin: 0 24px 24px 0;
}


/***********************************************Repertoire****************************************************/

.columns{
  column-count: 2;
  column-gap: 48px;
}

.columns p{
  margin: 0 0 5px 0;
}

.flex-box{
  display: flex;
  width:100%;
  flex-direction: column;

}

.audioPlayerRepertoire {
    width: 240px;
    height: 24px;
    align-self: flex-start;
    margin-bottom: 12px;
}

/***********************************************Aufnahmen****************************************************/

.flex-container{
  display: flex;
  width:100%;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.flex-container div{
  margin: 0 24px 0 0px;
}

iframe{
  margin: 0 24px 24px 0;
  display: flex;

}

.flex-container h2{
  font-size: 1.2em;
}

audio {
    width: 300px;
    height: 54px;
}

.audioPlayer {
    width: 180px;
    height: 24px;
}

video{
  margin-bottom: 12px;
}


/***********************************************Kontakt****************************************************/

form{
  display: flex;
  flex-direction: column;
  padding: 0 0 24px 0;
  max-width: 50%;
}

input{
  margin-bottom: 24px;
  padding: 12px 6px 12px 6px;
  font-family: 'Work Sans', sans-serif, Times;
  font-size: 0.9em;
}

textarea{
  padding: 6px 6px 96px 6px;
  margin: 0 0 48px 0;
  font-family: 'Work Sans', sans-serif, Times;
  font-size: 0.9em;
  line-height: 1.5em;
}

form .button{
  max-width: 40%;
  background-color: transparent;
}

label{
  padding-bottom: 2px;
}

.confirmation-box{
  background-color: var(--lila);
  color: var(--white);
  padding: 12px;
  margin: 24px 0 24px 0;
  border: 1px solid;
  max-width: 50%;
}

.confirmation-box h2{
  font-size: 1.3em;
  margin: 12px 0 12px 0;

}

.confirmation-box p{
  margin: 6px 0 6px 0;
}

/***********************************************Links****************************************************/

.links a{
  font-size: 1em;
}

/***********************************************Footer****************************************************/

footer {
    margin-top: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px 80px 20px 80px;
    display: flex;

}

.socialMedia{
  display: flex;
  justify-content: flex-end;
  width:50%;
  align-self: center;
}

footer img {
  margin-left: 10px;

}

.contact{
  width:50%;
  align-self: center;
}

footer a{
  font-size: 1em;
  font-weight: 200;
}

/************************ Burger Menu **********************/


#page-wrap {
/* Prevent sidebar from showing a scrollbar on page */
overflow: hidden;
}


/* Individual item */


.bm-item {
display: inline-block;
/* Our sidebar item styling */
text-decoration: none;
margin: 10px 0 5px 0;
color: var(--black);
transition: color 0.2s;
padding-left: 20px;

}

/* Change color on hover */


/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
background: var(--lila);
padding: 1px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
height: 24px;
width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
background: var(--lila);
}

/* General sidebar styles */
.bm-menu {
background: var(--white);
font-size: 1em;
overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
fill: #7787A6;
}

/* Wrapper for item list */
.bm-item-list {
color: var(--lila);
}

/* Styling of overlay */
.bm-overlay {
background: var(--lila);
}

/***********************************************RWD 1200px****************************************************/
@media only screen and (max-width: 1200px) {

  .img-about{
    max-width: 100%;
  }

  .hide-on-small{
    display: none;
  }

  .hide-on-large{
    display: block;
  }

  .rectangle {
      margin: 0 15% 0 15%;

  }

  h2{
    margin:24px 0 12px 0;
  }



/************Home**********************/

  .teaser-container{
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .teaser{
    display: block;
    width: 100%;
    margin: 0 0 96px 0;
  }

  .teaser-text{
    margin: 24px 24px 24px 24px;
  }

  .teaser img{
    max-width: 70%;
  }

  .teaser h2{
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 24px 0 12px 0;

  }

/************Aufnahmen**********************/

  iframe{
    margin: 0 0px 24px 0;
    display: flex;
    max-width: 100%;
  }

  .hochformat{

  }

  .flex-container{
  flex-direction: column;

  }

  .flex-container div{
  margin: 0 0 0 0;
  }



}


/***********************************************RWD 900px****************************************************/
@media only screen and (max-width: 900px) {



  .rectangle {
      margin: 0 5% 0 5%;
      padding: 0 24px 0 24px;
      border: solid rgba(255, 255, 255, 1) 10px;
  }

  h1{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.7em;
    margin:24px 0 12px 0;
  }

  h2{
    font-size: 1.3em;
    margin: 24px 0 6px 0;
  }

  p{
    margin: 6px 0 24px 0;
  }



  .lead{
    font-size: 1.1em;
    margin: 0 0 24px 0;
  }
/*
  .background {
    background-image: url('bg_mobile.png');
    background-position: 0% 50%;
    background-size: 100%;
    background-attachment: fixed;
  }*/

  body:after{
    background-image: url('./pictures/bg_mobile.png');
    background-position: 0% 50%;
    background-size: 100%;
  }


/***********************************************Header****************************************************/
  .navBar{
    display: none;
  }

  .logo {
      width: 50%;
  }

  .bm-burger-button {
    position: absolute;
    width: 48px;
    height: 20px;
    display: block;
    top: 40px;
    left: 5%;
  }


  .burger-container{
    display: block;
  }


/***********************************************Home****************************************************/

.teaser-text{
  padding: 0;
  margin: 0 6px 24px 6px;
}

/*
.teaser h2{
  margin-block-start: 0;
  margin-block-end: 0;
  margin: -30px 0 12px 0;
}*/

/***********************************************Angebot****************************************************/
.img-angebot{
  max-width: 100%;
  float: none;
  margin: 0;
}


/***********************************************Repertoire****************************************************/

  .columns{
    column-count: 1;
  }

  /***********************************************Aufnahmen****************************************************/

video{
  width: 100%;
  margin-top: 12px;
}

  /***********************************************Kontakt****************************************************/

  form{
    max-width: 100%;
  }

  form .button{
    max-width: 50%;
  }

  .confirmation-box{
    max-width: 100%;
  }



/***********************************************Footer****************************************************/

footer{
  padding: 24px 5% 24px 5%;
}

.contact{
  width:70%;
  align-self: center;
}

.socialMedia{
  display: flex;
  justify-content: flex-end;
  width:30%;
  align-self: center;
}

footer img {
  max-width: 70%;
}


}

/***********************************************RWD 1800px****************************************************/
@media only screen and (min-width: 1800px) {

.last-p{
  margin-bottom: 48px;
}
}
